@import "abstracts/_abstracts";
.HomeBenefitPoint {
	$block: &;
	background: var(--colorBrand);
	color: text-contrast(var(--colorBrand));
	padding: var(--spaceXl) 0;
	position: relative;

	&-frame {
		position: relative;
	}

	&-title {
		color: text-contrast(var(--colorBrand));
		font-size: clamp(1.5rem, 3vw, 2rem);
		line-height: 1.25;
		margin-bottom: 2rem;
	}

	&-image {
		position: absolute;
		inset: 0;
		left: 50%;
		clip: rect(0, auto, auto, 0);
		overflow: hidden;
		z-index: var(--layerNullZIndex);

		&:after {
			display: block;
			content: '';
			position: absolute;
			inset: 0;
			background-image: linear-gradient(90deg, var(--colorBrand) 0%, var(--colorBrandLight) 100%);
		}

		img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--transparent {
		background: transparent;
		color: var(--textColor);
		margin: 0;
	}

	&-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		gap: clamp(2rem, 5vw, 3.5rem);
	}
}